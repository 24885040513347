import React, { useState } from 'react';

import * as style from 'styles/components/account/monthlyOffer.module.scss';

import defaultIcon from '../../images/hammarbyLogoColor.png';

const MonthlyOffer = ({ offer }) => {

    const buildDate = (matchDate) => {
        const date = new Date(matchDate);
        const formattedDate = date.toLocaleDateString('sv-SE', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });

        return `${formattedDate}`;
    }

    const [showMore, setShowMore] = useState(false);

    function doShowMore(show) {
        setShowMore(show);
    }

    return (
        <div className={style.monthlyOffer__offer}>

            <div className={style.monthlyOffer__offer__img}>
                <img className={style.monthlyOffer__offer__bg} src={offer.imageUrl} alt="Headerbild" />
            </div>

            <div className={style.monthlyOffer__offer__header}>
                <div className={style.monthlyOffer__offer__header__icon}>
                    {offer.company && offer.company.image ? (
                        <img src={offer.company.image} alt={offer.company.name} />
                    ) : (
                        <img className={style.monthlyOffer__header__icon__defaultIcon} src={defaultIcon} alt="Hammarby" />
                    )}
                </div>
                <div>
                    {offer.company && offer.company.name &&
                        <h3>{offer.company.name}</h3>
                    }
                    <h4>{offer.category.name}</h4>
                </div>
            </div>

            <div className={style.monthlyOffer__offer__description}>
                <h4>{offer.title}</h4>
                <p>
                {showMore ? offer.description : `${offer.description.substring(0, 350)}`}
                {offer.description.length > 350 && !showMore && "..."}
                </p>
                {offer.description.length > 350 && (
                    <div className={style.monthlyOffer__offer__showMore__container}>
                        <button type="button" className={style.monthlyOffer__offer__showMore} onClick={() => doShowMore(!showMore)}>{showMore ? "Visa mindre" : "Visa mer"}</button>
                    </div>
                )}

                <p className={style.monthlyOffer__offerPromo}>
                    {offer.promoCode && offer.promoType !== 'link' &&
                        `Rabattkod: ${offer.promoCode}`
                    }
                    <br />Giltig t.om: {buildDate(offer.endDate)}
                </p>
            </div>
            <div className={style.monthlyOffer__offer__footer}>
                {offer.link &&
                    <a className="cta-white" href={offer.link} target="_blank" rel="noreferrer">Ta del av erbjudandet</a>
                }
            </div>

        </div>
    );
};

export default MonthlyOffer;
