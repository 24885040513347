// extracted by mini-css-extract-plugin
export var container = "monthlyOffer-module--container--WHvT+";
export var monthlyOffer = "monthlyOffer-module--monthlyOffer--nhJF5";
export var monthlyOffer__defaultIcon = "monthlyOffer-module--monthlyOffer__defaultIcon--E8cv7";
export var monthlyOffer__header = "monthlyOffer-module--monthlyOffer__header--6Xok-";
export var monthlyOffer__header__icon = "monthlyOffer-module--monthlyOffer__header__icon--D27ZW";
export var monthlyOffer__header__icon___img = "monthlyOffer-module--monthlyOffer__header__icon___img--D-AKu";
export var monthlyOffer__header__icon__defaultIcon = "monthlyOffer-module--monthlyOffer__header__icon__defaultIcon--VWvZy";
export var monthlyOffer__offer = "monthlyOffer-module--monthlyOffer__offer--fZ-qf";
export var monthlyOffer__offerPromo = "monthlyOffer-module--monthlyOffer__offerPromo--s-J+w";
export var monthlyOffer__offer__bg = "monthlyOffer-module--monthlyOffer__offer__bg--8yGgd";
export var monthlyOffer__offer__defaultIcon = "monthlyOffer-module--monthlyOffer__offer__defaultIcon--cxXbq";
export var monthlyOffer__offer__description = "monthlyOffer-module--monthlyOffer__offer__description--OMFKj";
export var monthlyOffer__offer__footer = "monthlyOffer-module--monthlyOffer__offer__footer--Ejfm3";
export var monthlyOffer__offer__header = "monthlyOffer-module--monthlyOffer__offer__header--kXeaF";
export var monthlyOffer__offer__header__icon = "monthlyOffer-module--monthlyOffer__offer__header__icon--gGzXd";
export var monthlyOffer__offer__header__icon___img = "monthlyOffer-module--monthlyOffer__offer__header__icon___img--7O2FY";
export var monthlyOffer__offer__header__icon__defaultIcon = "monthlyOffer-module--monthlyOffer__offer__header__icon__defaultIcon--FWnWm";
export var monthlyOffer__offer__img = "monthlyOffer-module--monthlyOffer__offer__img--xD8Au";
export var monthlyOffer__offer__showMore = "monthlyOffer-module--monthlyOffer__offer__showMore---1CZ-";
export var monthlyOffer__offer__showMore__container = "monthlyOffer-module--monthlyOffer__offer__showMore__container--UPfko";
export var monthlyOffer__offer__text = "monthlyOffer-module--monthlyOffer__offer__text--+cqBZ";