import React from 'react';
import Slider from "react-slick";

import MonthlyOffer from 'components/account/MonthlyOffer';
import * as style from 'styles/components/account/listOffersMonthly.module.scss';
import * as slickStyle from 'styles/components/utils/slider.module.scss';
import "slick-carousel/slick/slick.css";

const SlickArrow = (props)  => {
    const { className, onClick } = props;
    return(
        <button type="button" onClick={onClick} className={`arrow ${className}`} >
            <i className="ico-chevron" aria-hidden="true" />
            <span className="visually-hidden">Flubar</span>
        </button>
      )
}

const ListMonthlyOffers = ({ offers }) => {

    const sliderSettings = {
        dots: false,
        arrow: true,
        infinite: false,
        speed: 10,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <SlickArrow to="next" />,
        prevArrow: <SlickArrow to="prev" />,
        swipe: false,
        swipeToSlide: false,
        draggable: false,
    };

    return (
        <>
            <h3 className="title-xs semibold">Månadens erbjudande</h3>
            <div className={style.monthlyOffers__container}>
                <Slider {...sliderSettings} className={slickStyle.globalSlider__content}>
                    {offers.map((offer, i) => (
                        <MonthlyOffer key={i} offer={offer} />
                    ))}
                </Slider>
            </div>
        </>
    );
}

export default ListMonthlyOffers;
