import React, { useState } from 'react';

import Offer from 'components/account/Offer';
import * as style from 'styles/components/account/listOffers.module.scss';

const ListOffers = ({ offers }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [offersPerPage] = useState(12);

    const totalPages = Math.ceil(offers.length / offersPerPage);

    function handlePaging(direction) {
        if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        } else if (direction !== 'prev' && currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1)
        }
        window.scrollTo(0, 640);
    }

    const chunkedData = offers.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/offersPerPage)
        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, []);

    return (
        <div className={style.pages__container} id="offer">
            {chunkedData.map((chunk, index) => (
                <div className={`${style.pages__page} ${(index + 1 === currentPage ? 'visible' : 'hidden') }`}>
                    <div className={style.listOffers__offersContainer} key={`div${index}`}>
                        {chunk.map((item, i) => (
                            <Offer key={i} offer={item} />
                        ))}
                    </div>
                </div>
            ))}
            {totalPages > 1 && (
                <div className={style.pages__pagination}>
                    <button type="button" className={`${style.pages__arrow} ${style.pages__arrow__prev}`} onClick={() => handlePaging('prev')} disabled={currentPage <= 1}>
                        <i className="ico-chevron" aria-hidden="true" />
                        <span>Prev</span>
                    </button>
                    <button type="button" className={`${style.pages__arrow} ${style.pages__arrow__next}`} onClick={() => handlePaging('next')} disabled={currentPage >= totalPages}>
                        <i className="ico-chevron" aria-hidden="true" />
                        <span>Next</span>
                    </button>
                </div>
            )}
        </div>
    );
}

export default ListOffers;
