const fetchAPI = async (requestUrl, userToken) => {
    const requestHeaders = new Headers();
    requestHeaders.append('Authorization', userToken);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('x-api-key', userToken);

    const requestParams = {
        method: 'GET',
        headers: requestHeaders,
    };

    const response = await fetch(requestUrl, requestParams);

    if (!response.ok) {
        throw new Error(`${response.status} : ${response.statusText}`);
    }

    return response.json();
};

export default fetchAPI;
