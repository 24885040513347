// extracted by mini-css-extract-plugin
export var offer = "offer-module--offer--l65bT";
export var offer__bg = "offer-module--offer__bg--mKyuu";
export var offer__bold = "offer-module--offer__bold--IhJ46";
export var offer__description = "offer-module--offer__description--9e--u";
export var offer__footer = "offer-module--offer__footer--OVkir";
export var offer__header = "offer-module--offer__header--rJ2us";
export var offer__header__icon = "offer-module--offer__header__icon--rMVU1";
export var offer__header__icon___img = "offer-module--offer__header__icon___img--h3brV";
export var offer__header__icon__defaultIcon = "offer-module--offer__header__icon__defaultIcon--7RSGl";
export var offer__img = "offer-module--offer__img--UyDyt";
export var offer__offerPromo = "offer-module--offer__offerPromo--jBmdc";
export var offer__showMore = "offer-module--offer__showMore--36zk2";
export var offer__showMore__container = "offer-module--offer__showMore__container--18KB0";
export var offer__text = "offer-module--offer__text--UDEA5";